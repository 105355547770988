.dash {
  background: #f3f3f8;
  /* min-height: 100vh; */
}

.dashboard {
  background: #f3f3f8;
  /* min-height: 100vh; */
}
.sub-text {
  margin: 50px;
  text-align: center;
  line-height: 26.745px;
}

.first-comp {
  margin-left: 2%;
  margin-right: 2%;
}

.sub-text1 {
  margin: 10px;
  text-align: center;
}

.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button {
  margin-bottom: 10px;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .button {
    flex-basis: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
}
