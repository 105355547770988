.response-template {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .response-template {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
