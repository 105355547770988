/* Download Link Styles */
.download-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgb(
    64,
    81,
    167
  ); /* Change to your preferred button color */
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 10px; /* Add some space to the left of the button */
}
