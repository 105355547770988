.boxStyle {
  background-color: white;
  flex-basis: calc(20.33% - 20px);
  min-width: 100px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  /* min-width: 10vw; */
  min-width: 23vw;
  border: 1px solid #cfcfcf;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  margin: 9px;
}

.cardStyle {
  border-radius: 5px;
  margin-bottom: 10px;
}

.parentCard {
  background-color: white;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

@media (max-width: 767.98px) {
  .boxStyle {
    min-width: 100px;
    box-shadow: 0px 4px 8px rgba (0, 0, 0, 0.1);
    text-align: center;
    margin: 10px;
    min-width: 37vw;
    border: 1px solid #cfcfcf;
    padding: 10px;
    margin-bottom: 10px;
    display: inline-grid;
    border-radius: 8px;
    width: 43vw;
    height: 19vh;
    align-items: inherit;
    justify-content: initial;
  }

  .cardStyle {
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .parentCard {
    background-color: white;
    border-radius: "5px";
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
  }
}

@media screen and (min-width: 767.98px) and (max-width: 2430px) {
  .headingStat {
    text-align: left !important;
    margin-left: 2% !important;
  }
}
