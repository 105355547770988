.dash {
  background: #f3f3f8;
  /* min-height: 100vh; */
}

.signatory-form-container {
  background: rgb(243, 243, 249);
}

.submission-header {
  background: #fffeff;
  padding: 5px;
  padding-left: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 50px;
}

.submission-title {
  color: black;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.breadcrumb {
  display: flex;
  gap: 7px;
  align-items: center;
}

.breadcrumb-link {
  color: black;
  margin-top: 0px !important;
  font-size: 13px;
  text-decoration: none;
  color: #878a99;
}

.breadcrumb-icon {
  font-size: 8px;
  color: #878a99;
}

.page-content {
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
}

.form-title {
  background: #ffffff;
  margin-bottom: 20px;
  padding-top: 35px;
  padding-bottom: 35px;
}

.form-title-text {
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0;
  font-size: 24px;
}

.form-wrapper {
  background: #ffffff;
  padding: 20px;
}

.form-box {
  text-align: center;
}

.table-container {
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-weight: 400;
  font-size: 13px;
}

.data-table th,
.data-table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.data-table th {
  text-align: left;
  background-color: #f2f2f2;
}

.data-table td:last-child {
  text-align: right;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.approve-button,
.reject-button {
  color: white;
  border-radius: 5px;
  border: 1px solid #405089;
  background: rgb(64, 81, 167);
  outline-color: navajowhite;
  font-size: 13px;
  min-width: 70px;
  height: 35px;
  margin: 0 10px;
}

/* Responsive Styles */

@media (max-width: 767px) {
  .form-title-text {
    font-size: 20;
  }
}
