.auth-bg-cover {
  background: linear-gradient(-45deg, #405189 50%, #0ab39c);
}

.auth-one-bg {
  background-image: url(../../assets/images/profile-bg.jpg);
  background-position: center;
  background-size: cover;
  /* background: linear-gradient(to right, #364574, #405189); */
  filter: opacity(0.5) drop-shadow(0 0 0 #364574);
}

.auth-page-wrapper .auth-page-content {
  position: relative;
  z-index: 2;
  width: 100%;
}
/* .auth-one-bg .bg-overlay {
  background: -webkit-gradient(linear, left top, right top, from(#364574), to(#405189));
  background: linear-gradient(to right, #364574, #405189);
  opacity: .9;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
} */
