.navbar {
  background: #ffffff;
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  padding: 20px 5%;
}

@media screen and (max-width: 500px) {
  .dropMenu {
    width: 100vw;
  }
}

.dropdown-toggle::after {
  display: none !important;
}
