.dash {
  background: #f3f3f8;
  /* min-height: 100vh; */
}

.dashboard {
  background: #f3f3f8;
  /* min-height: 100vh; */
}
.sub-text {
  margin: 50px;
  text-align: center;
  line-height: 26.745px;
}

.first-comp {
  margin-left: 2%;
  margin-right: 2%;
}

.btn-group-1 {
  text-align: center;
  /* padding: 5%; */
}

.sub-text1 {
  margin: 20px;
  text-align: center;
}

.btn-group-2 {
  text-align: center;
}

.btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  border-radius: 0.2rem;
}

.btn-primary {
  color: #fff;
  background-color: #405189;
  border-color: #405189;
}
