/* Styling for the entire page */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

/* Styling for the popup container */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  margin-top: 20px;
}

/* Styling for the popup content */
.popup-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
  z-index: 201;
  overflow: auto;
  max-height: 80vh;
  margin-top: 10px;
}

/* Styling for the close button */
.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  padding: 0;
}

.popup-close-btn:focus {
  outline: none;
}

.popup-close-btn:hover {
  color: #f44336;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .popup-content {
    max-width: 95%;
  }
}

/* ... Previous styles ... */

/* Style the headings */
h1,
h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

/* Style the paragraphs */
p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

/* Style the link */
a {
  color: #007bff;
  text-decoration: none;
}

/* Style the link on hover */
a:hover {
  text-decoration: underline;
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table,
th,
td {
  border: 1px solid #ddd;
}

th,
td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

/* Style the italic text */
.italic-text {
  font-style: italic;
  color: #555;
}

/* Style the bold text */
.bold-text {
  font-weight: bold;
  color: #007bff;
}
