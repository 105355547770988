.box-response {
    width: 80%;
    height: 150px;
}

@media only screen and (max-width: 600px) {
  .btn-response {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .box-response {
    width: 100%;
    height: 100px;
  }
}
