.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

/* Example CSS for styling the OTPComponent */

/* Styling for the OTP input fields */
.otp-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  margin: 0 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Styling for the separator between OTP input fields */
.otp-input span {
  margin: 0 4px;
  color: #777;
  font-size: 18px;
}

/* Styling for the OTP input fields when focused */
.otp-input input:focus {
  outline: none;
  border-color: #007bff;
}

/* Styling for the OTP input fields when input is entered */
.otp-input input:not(:placeholder-shown) {
  background-color: #f8f9fa;
}
